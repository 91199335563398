import Link from 'next/link'
import ErrorPage from '@ui/blueotter-camp/components/error-page';
import Button from '@ui/blueotter-camp/components/button';
import Logo from '@ui/blueotter-camp/components/logo';


export default function Custom404() {

    return (

        <div className="c-layout-gateway" >
            <div className="background"><img src="/static/images/background/water.jpg" alt="" /></div>

            <ErrorPage>
                <Logo src='/static/brand/logo-large.svg' size='medium' />
                <h1>404</h1>
                <h2>Oops! something went wrong.<br></br> please try again later </h2>
                <Link href="/">
                    <Button label="Go back home" />
                </Link>

            </ErrorPage>


        </div >





    )

}


